import { Grid, Layout } from 'antd';
import { Header, Content, Footer } from 'antd/es/layout/layout';
import React from 'react';

function App() {
  const { useBreakpoint } = Grid;
  const { xs, sm, md, lg, xl, xxl  } = useBreakpoint(); 
  const fontSize = lg ? '18px' : md ? '16px' : '14px' 

  console.log(`fontSize: [${fontSize}]`)

  return (
    <Layout style={{ height: '100vh', backgroundColor: 'white' }}>
      <Header className='header'>
        <img src='./images/logo.jpg' alt="logo" />
      </Header>
      <Content>
          <div className='content nowrap' style={{ fontSize: fontSize}}>
            <br/>
            <div className="bold">MARIFLOOR</div>
            <br/>
            <div>PATENTED RAISED FLOOR SYSTEM</div>
            <br/>
            <div>Light, self-levelling, fast.</div>
            <div>Saving weight, cost and build lead time.</div>
            <br/>
            <img src="./images/sample.jpg" alt="sample" className="responsive" />
            <br/>
            <div>Marifloor</div>
            <div>Koperhoek 10a</div>
            <div>3162LA Rhoon</div>
            <br/>
            <div><a href = "mailto: info@marifloor.yachts">info@marifloor.yachts</a></div>
          </div>
      </Content>
    </Layout>
  );
}

export default App;
